"use client";

import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { IMenuItemGroup } from "./SideMenuContent";
import { TextButton } from "../../TextButton";
import useClickOutside from "../../hooks/useClickOutside";
import { SubMenu } from "./SubMenu";
import { usePathname } from "next/navigation";
import { cn } from "@costar-ui/cosmos";
import useClickLinkInside from "../../hooks/useClickLinkInside";

interface IMenuItemWithSubMenu {
  name?: string;
  subMenuOptions: IMenuItemGroup;
}

export const MenuItemWithSubMenu = (props: IMenuItemWithSubMenu) => {
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef(null);

  const pathName = usePathname();

  useClickOutside(node, isOpen, () => {
    setIsOpen(false);
  });

  useClickLinkInside(node, isOpen, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setIsOpen(false);
  }, [pathName]);

  return (
    <div ref={node} className="relative">
      <TextButton
        onClick={toggleAccordion}
        className={cn(
          "flex w-full items-center justify-between px-4 py-2 leading-6 hover:bg-neutral-light",
          { "bg-neutral-light": isOpen }
        )}
      >
        <h3 className="text-18/6">{props.name}</h3>
        <Image
          className={cn("transition-transform duration-200 lg:rotate-0", {
            "rotate-90 lg:rotate-180": isOpen,
          })}
          src="/menu-chevron.svg"
          alt="Menu chevron icon"
          width={24}
          height={24}
          priority
        />
      </TextButton>
      <div
        className={cn(
          "top-0 bg-content px-4 py-2 transition duration-300 ease-in-out lg:absolute lg:right-[-252px] lg:w-[250px]",
          {
            block: isOpen,
            hidden: !isOpen,
          }
        )}
      >
        <SubMenu subMenuOptions={props.subMenuOptions} />
      </div>
    </div>
  );
};
