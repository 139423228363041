import Image from "next/image";
import { cn } from "@costar-ui/cosmos";

export interface IAvatar {
  text?: string;
  className?: string;
}

export const Avatar = (props: IAvatar) => {
  return (
    <div
      automation-id="user-profile-avatar"
      className={cn(
        "flex h-[24px] w-[24px] items-center justify-center rounded-full bg-primary py-1 text-content outline-none",
        props.className
      )}
    >
      {props.text ? (
        <span className="text-[10px]">{props.text}</span>
      ) : (
        <Image
          className="m-auto mt-[-2px]"
          src="/avatar.svg"
          alt="Avatar Icon"
          width={20}
          height={20}
          priority
        />
      )}
    </div>
  );
};
