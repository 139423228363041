import Link from "next/link";
import { IMenuItemGroup } from "./SideMenuContent";
import {
  ClientTrackingEvent,
  ClientTrackingArea,
  trackingDetails,
} from "../../../helpers/event-tracking-helpers";

export interface ISubMenu {
  subMenuOptions: IMenuItemGroup;
}

export const SubMenu = (props: ISubMenu) => {
  return (
    <ul className="flex flex-col">
      <p className="hidden cursor-default pb-2 text-14 text-neutral-medium lg:block">
        {props.subMenuOptions.groupName}
      </p>
      {props.subMenuOptions.items.map((option) => {
        return (
          <li
            className="flex cursor-pointer flex-col justify-between"
            key={option.name}
          >
            <Link
              className="w-full px-4 py-2 text-18 leading-5 hover:bg-neutral-light hover:text-primary lg:px-0"
              href={option?.url || "/"}
              data-track-click
              data-track-details={trackingDetails({
                event: ClientTrackingEvent.NAVIGATION_CLICK,
                area: ClientTrackingArea.HAMBURGER_MENU,
              })}
            >
              {option.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
