"use client";

import { signIn } from "next-auth/react";
import { ReactNode } from "react";
import { cva, type VariantProps } from "class-variance-authority";

interface ISignInButton {
  children?: ReactNode;
}

export enum SignInButtonVariants {
  buttonVariant = "buttonVariant",
  linkVariant = "linkVariant",
}

const signInButtonVariants = cva(["rounded-sm"], {
  variants: {
    signInButtonVariant: {
      buttonVariant:
        "flex grow-0 items-center bg-primary px-2 py-[2px] font-sans text-16 leading-5 text-content md:text-14 w-[117px] md:w-[104px]",
      linkVariant:
        "background-transparent text-left text-input-text outline-none hover:text-primary",
    },
  },
});

interface ISignInButtonProps
  extends ISignInButton,
    VariantProps<typeof signInButtonVariants> {}

export const SignInButton = (props: ISignInButtonProps) => {
  const loginClickHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    signIn("costar");
  };

  const { children, signInButtonVariant } = props;
  return (
    <a
      automation-id="header-sign-in-button"
      onClick={loginClickHandler}
      className={signInButtonVariants({ signInButtonVariant })}
      type="button"
      href={"#"}
    >
      {children}
    </a>
  );
};
