"use client";
import { usePathname } from "next/navigation";
import { INavigationItem, Navigation, setActiveNavigation } from "./Navigation";
import { cn } from "@/utils/utils";

interface IMultiNavigation {
  navigationItems: INavigationItem[];
}

export const LargeScreenNavigation = (props: IMultiNavigation) => {
  const pathName = usePathname();

  const navItemsWithActiveState = setActiveNavigation(
    props.navigationItems,
    pathName
  );

  const isDirectoryPage =
    pathName.includes("/annuaire/individus") ||
    pathName.includes("/annuaire/societes");

  //check to see if in detail page of prefessionels or socites
  const isSearchDetailPage =
    (pathName.includes("/annuaire/individus" + "/") ||
      pathName.includes("/annuaire/societes" + "/")) &&
    (pathName.includes("/bio") ||
      pathName.includes("/resume") ||
      pathName.includes("/sites") ||
      pathName.includes("/actualites") ||
      pathName.includes("/collaborateurs"));

  const subnavItemsWithActiveState =
    !isSearchDetailPage &&
    navItemsWithActiveState.find((item) => {
      return item.active;
    })?.nestedItems;

  return (
    <>
      <Navigation
        items={navItemsWithActiveState}
        className="hidden border-b border-neutral-medium bg-secondary text-16 text-content lg:block"
      />
      {subnavItemsWithActiveState && (
        <Navigation
          items={subnavItemsWithActiveState}
          className={cn(
            "hidden border-b border-neutral-medium bg-neutral-lighter font-sans text-input-text lg:block min-[1024px]:text-14 min-[1145px]:text-16",
            {
              block: isDirectoryPage,
            }
          )}
          sideElement={<div id="subnav-side-element" className="flex h-full" />}
          isSubNav={true}
        />
      )}
    </>
  );
};
