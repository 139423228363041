import { MenuItemWithSubMenu } from "./MenuItemWithSubMenu";
import { MenuLink } from "./MenuLink";

export interface IMenuItem {
  element?: JSX.Element;
  active?: boolean;
  name?: string;
  url?: string;
  nestedItems?: IMenuItemGroup[];
  target?: string;
}

export interface IMenuItemGroup {
  disableGroupStyle?: boolean;
  groupName?: string;
  items: IMenuItem[];
}
export interface ISideMenuContent {
  items: IMenuItemGroup[];
}

export const SideMenuContent = (props: ISideMenuContent) => {
  const { items } = props;
  return (
    <ul>
      {items.map((group, index) => {
        return (
          <li
            key={group.groupName || index}
            automation-id={`side-menu-group-${index}`}
          >
            {index !== 0 && !group.disableGroupStyle && (
              <div
                automation-id="side-menu-description"
                className="px-4 py-1 text-14 leading-6 text-neutral-dark"
              >
                <div className="border-t-2 border-neutral-medium"></div>
                {group.groupName}
              </div>
            )}
            <ul>
              {group.items.map((option, subindex) => {
                return (
                  <li
                    automation-id={`side-menu-group-${index}-option-${subindex}`}
                    className={`flex flex-col justify-between`}
                    key={`${option.name}-${subindex}`}
                  >
                    {option.element && option.element}
                    {!option.nestedItems && option.url && (
                      <MenuLink href={option.url} target={option.target}>
                        {option.name}
                      </MenuLink>
                    )}
                    {option.nestedItems && (
                      <>
                        <MenuItemWithSubMenu
                          name={option.name}
                          subMenuOptions={option.nestedItems[0]}
                        ></MenuItemWithSubMenu>
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
