"use client";

import Image from "next/image";
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { cn } from "@costar-ui/cosmos";
import { searchOption } from "./search-options";
import { trackEventApi } from "../../../common/hooks/track-event-api";
import {
  ClientTrackingEvent,
  SearchType,
} from "../../../helpers/event-tracking-helpers";
import { buildSearchURL } from "../../../search-page/search-page-utils";

interface IMessages {
  searchButtonDescription: string;
}
interface ISearchInput {
  className?: string;
  appBaseUrl: string;
  messages: IMessages;
}

const searchKey = "keyword";

export const SearchInput = (props: ISearchInput) => {
  const pathName = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  const { messages, className, appBaseUrl } = props;

  const [searchInput, setSearchInput] = useState(
    searchParams.get(searchKey) || ""
  );

  const searchIcon = useMemo(
    () => (
      <button
        type="submit"
        aria-label={messages.searchButtonDescription}
        title={messages.searchButtonDescription}
        className="absolute right-2 top-1"
        automation-id="search-bar-submit-button"
      >
        <Image
          src="/magnifying-glass.svg"
          alt="Search Icon"
          width={24}
          height={24}
          priority
        />
      </button>
    ),
    [messages.searchButtonDescription]
  );

  const onClear = useCallback(
    (e: MouseEvent) => {
      e?.preventDefault();
      if (searchInput) setSearchInput("");
      setInputIcon(searchIcon);

      if (pathName === "/actualites/recherche") {
        const to = searchParams.get("to");
        const from = searchParams.get("from");
        const url = buildSearchURL({
          baseUrl: appBaseUrl,
          redirectUrl: "/actualites/recherche",
          thematiques: searchParams.getAll("thematique") as
            | string[]
            | undefined,
          categories: searchParams.getAll("categorie") as string[] | undefined,
          to: to ? parseInt(to) : undefined,
          from: from ? parseInt(from) : undefined,
        });
        router.push(url);
      }
    },
    [appBaseUrl, pathName, router, searchIcon, searchInput, searchParams]
  );

  const clearIcon = useMemo(
    () => (
      <button
        type="reset"
        aria-label={messages.searchButtonDescription}
        title={messages.searchButtonDescription}
        className="absolute right-2 top-1"
        automation-id="search-bar-submit-button"
        onClick={onClear}
      >
        <Image
          src="/clear-icon.svg"
          alt="Search Icon"
          width={24}
          height={24}
          priority
        />
      </button>
    ),
    [messages.searchButtonDescription, onClear]
  );

  const [inputIcon, setInputIcon] = useState<ReactNode>(
    !!searchInput ? clearIcon : searchIcon
  );

  // Clear search bar if not on search page
  useEffect(() => {
    if (pathName !== "/actualites/recherche") {
      setSearchInput("");
    } else {
      setSearchInput(searchParams.get(searchKey) || ""); // Back button clicked
    }
  }, [pathName, searchParams]);

  useEffect(() => {
    if (searchInput) {
      setInputIcon(clearIcon);
    } else {
      setInputIcon(searchIcon);
    }
  }, [clearIcon, searchIcon, searchInput]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    trackEventApi(
      {
        event: ClientTrackingEvent.SEARCH_PERFORMED,
        searchCriteria: {
          keyword: searchInput ? searchInput : undefined,
          searchType: SearchType.ARTICLE, //TODO: refine this??
        },
      },
      pathName
    );
    const formData = new FormData(e.target as HTMLFormElement);

    const url = new URL(searchOption.url, appBaseUrl);
    url.searchParams.set(searchKey, formData.get("searchKeyword") as string);

    if (pathName === "/actualites/recherche") {
      const to = searchParams.get("to");
      const from = searchParams.get("from");
      const url = buildSearchURL({
        baseUrl: appBaseUrl,
        redirectUrl: "/actualites/recherche",
        keyword: formData.get("searchKeyword") as string,
        thematiques: searchParams.getAll("thematique") as string[] | undefined,
        categories: searchParams.getAll("categorie") as string[] | undefined,
        to: to ? parseInt(to) : undefined,
        from: from ? parseInt(from) : undefined,
      });
      router.push(url);
    } else {
      router.push(url.href);
    }
  };

  return (
    <form
      method="post"
      onSubmit={handleSubmit}
      autoComplete="off"
      className={cn(
        "flex grow-[2] flex-col sm:max-w-[390px] lg:max-w-[600px] lg:flex-row",
        className
      )}
      automation-id="search-bar-container"
    >
      <section className="relative w-full">
        <input
          name="searchKeyword"
          type="text"
          maxLength={150}
          placeholder={searchOption.placeholder}
          className="h-8 w-full text-ellipsis rounded-r-sm border border-neutral-medium px-2 py-1 pr-10 max-lg:rounded-l-sm"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          automation-id="search-bar-input"
        />
        {inputIcon}
      </section>
    </form>
  );
};

export const SearchInputFallBack = (props: ISearchInput) => {
  const { messages, className } = props;

  return (
    <form
      method="post"
      autoComplete="off"
      className={cn(
        "flex grow-[2] flex-col sm:max-w-[390px] lg:max-w-[600px] lg:flex-row",
        className
      )}
      automation-id="fallback-search-bar-container"
    >
      <section className="relative w-full">
        <input
          name="searchKeyword"
          type="text"
          maxLength={150}
          placeholder={searchOption.placeholder}
          className="h-8 w-full text-ellipsis rounded-r-sm border border-neutral-medium px-2 py-1 pr-10"
          automation-id="fallback-search-bar-input"
        ></input>
        <button
          type="submit"
          aria-label={messages.searchButtonDescription}
          title={messages.searchButtonDescription}
          className="absolute right-2 top-1"
          automation-id="fallback-search-bar-submit-button"
        >
          <Image
            src="/magnifying-glass.svg"
            alt="Search Icon"
            width={24}
            height={24}
            priority
          />
        </button>
      </section>
    </form>
  );
};
